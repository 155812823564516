import React, { useState } from "react";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm) {
      fetch("/api/Register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data === true) {
            window.location.href = "/jnf";
            console.log("Register successful");
          } else console.log("Register failed");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    console.log(errorText);
  };

  const validateForm = () => {
    // username check
    if (username.length < 1) {
      setErrorText("Username is required");
      return false;
    }

    if (username.length < 6) {
      setErrorText("Username must be at least 6 characters long");
      return false;
    }

    // password check
    if (password.length < 1) {
      setErrorText("Password is required");
      return false;
    }

    if (password.length < 6) {
      setErrorText("Password must be at least 6 characters long");
      return false;
    }

    // code check
    if (code.length < 1) {
      setErrorText("Code is required");
      return false;
    }

    // success
    return true;
  };

  return (
    <div className="form-container">
      <h2 className="form-label">Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-label">
          <label>
            Username :
            <input
              className="form-input"
              type="text"
              value={username}
              onChange={handleUsernameChange}
            />
          </label>
        </div>
        <div className="form-label">
          <label>
            Password :
            <input
              className="form-input"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
        </div>
        <div className="form-label">
          <label>
            Code :
            <input
              className="form-input"
              type="text"
              value={code}
              onChange={handleCodeChange}
            />
          </label>
        </div>
        <div>
          <button type="submit" className="form-button">
            Register
          </button>
        </div>
      </form>
      <div>{errorText !== "" ? <p>{errorText}</p> : null}</div>
    </div>
  );
}

export default Register;
